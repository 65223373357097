@import '../../../node_modules/react-quill/dist/quill.snow.css';

main {
  background-color: $bg_2;
}

.topbar {
  padding-top: 7px;
  padding-bottom: 5px;

  ul {
    display: flex;
    align-items: center;
    gap: 20px;

    li {

      p,
      a {
        color: #fff;
        margin-bottom: 0;
        font-size: 15px;
        line-height: normal;
      }

      // p {
      //   font-weight: 500;
      // }
    }
  }
}

.middle-bar {
  form.search-bar {
    display: flex;

    ul.search-form-list {
      position: absolute;
      width: 100%;
      left: 0;
      max-height: 200px;
      overflow-y: scroll;
      z-index: 99999;
      // border: 1px solid #fff;
      // border-top: 2px solid $brown;
      border-radius: 0 0 5px 5px;

      li {
        border-bottom: 1px solid $brown;
        padding: 5px 1pc;
        font-weight: 500;
        font-size: 15px;

        &:last-child {
          border: 0;
        }
      }
    }

    .form-control {
      background-color: transparent;
      border-color: $brown;
      border-radius: 0;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 350px;

      input {
        border: 0;
        background: transparent;
        width: 100%;
      }
    }

    .icon {
      font-size: 22px;
    }
  }

  .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul.right {
    display: flex;
    align-items: center;
    gap: 20px;

    .icon {
      font-size: 20px;
    }
  }
}

.bottom-bar {
  &.navbar {
    .navbar-nav {
      justify-content: center;
      gap: 20px;
      align-items: center;

      .dropdown:hover .dropdown-menu {
        opacity: 1 !important;
        visibility: visible !important;
        transition: all 0.5s;
      }

      .dropdown-menu {
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        border: 0;
        padding: 0;
        min-width: 280px;
        border-radius: 0;
        top: 43px;
        max-height: 600px;
        display: block !important;

        li a {
          background-color: #e9e4da;
          padding: 10px 10px;
        }

        a:hover {
          background-color: #d8d1c2;
        }
      }

      .nav-link {
        cursor: pointer;
        font-weight: 500;
        color: #000;
        font-size: 17px;

        &.icon {
          font-size: 22px;
        }

        &:hover {
          transition: all 0.5s;
          color: $brown2;
        }
      }
    }
  }
}

header {

  .topbar,
  .middle-bar,
  .bottom-bar {
    padding-left: 4pc;
    padding-right: 4pc;
  }
}

.custom-search {
  position: relative;

  .custom-search-list {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    position: absolute;
    left: 0;
    background: #fff;
    box-shadow: 0 0 20px #00000020;
    border-radius: 0 0 15px 15px;
    z-index: 99999;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    li {
      padding: 10px 12px;

      &:not(:last-child) {
        border-bottom: 1px solid lightgray;
      }
    }
  }
}

footer {

  .copyright,
  .footer {
    position: relative;
    z-index: 6;
  }
}

.footer {
  padding: 4pc 0;
  color: #000000;

  a:not(.btn) {
    color: inherit;
  }

  .icon,
  a {
    &:hover {
      color: $brown;
    }
  }

  .btns {
    .btn {
      --bs-btn-padding-y: 12px;

      svg {
        font-size: 20px;
      }
    }
  }

  .title {
    // color: $brown;
    margin-bottom: 1.5pc;
    font-weight: 600;
    font-size: 18px;
  }

  .social-links {
    display: flex;
    align-items: center;
    gap: 1pc;
    margin-top: 1pc;

    li a {
      color: $brown2;
      font-size: 20px;

      &:hover {
        color: $brown;
      }
    }
  }

  .links {
    li {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .icon {
        color: $brown;
      }
    }
  }
}

.copyright {
  padding: 10px 0;
  border-top: 1px solid #00000020;
  color: rgb(0, 0, 0);

  a {
    color: inherit;
  }
}

.shop-page {
  position: relative;

  .filter_btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    z-index: 99;
    border-radius: 0;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1pc;

    h1.title {
      font-size: 32px;
      margin-bottom: 5px;
    }
  }

  .form .form-select {
    padding: 10px 1pc;
    min-width: 240px;
    background-color: transparent;
    border: 1px solid #000 !important;
    color: #000 !important;
    border-radius: 0;

    &::placeholder {
      color: #000 !important;
    }
  }
}

.shop-filter {
  padding: 1pc;

  .title {
    font-weight: 600;
    margin-bottom: 1pc;
  }

  .comm-list li {
    margin-bottom: 5px;
  }

  .box {
    padding: 1pc 0;

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }

    &:last-child {
      padding-bottom: 0;
    }

    label {
      display: flex;
      align-items: center;
      gap: 7px;
      font-weight: 500;
      color: #57577a;
      cursor: pointer;
    }
  }

  .inner-list {
    padding-left: 1pc;
  }

  .list {
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;

      &-thumb {
        background: #bebcbc;
        border-radius: 10px;
      }

      &-track {
        background: #f1f1f1;
      }
    }
  }

  .price-range {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.modal-content {
  background-color: transparent;
  border: 0;
}

.custom-modal {
  position: relative;
  background-color: $bg_3;
  border-radius: 15px;
  padding: 2pc;

  .form .form-control,
  .form .form-select {
    padding: 12px 1pc;
  }

  .btn-close {
    position: absolute;
    top: 1pc;
    right: 1pc;
    opacity: 0.8;
    padding: 0;

    &.end {
      left: auto !important;
      right: 1pc !important;
    }
  }
}

.product-page {
  ul.mini-img {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    gap: 1pc;
    overflow-y: auto;
    max-height: 450px;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;

    img {
      height: 100px;
      width: 100px;
      cursor: pointer;
      object-fit: cover;
      border: 1px solid lightgray;
      padding: 3px;
    }
  }

  .left {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 1pc;
    height: 100%;
  }

  .big-img {
    overflow: hidden;
    flex: 1;

    // border-radius: 10px;
    &:hover img {
      transform: scale(1.5);
      transition: all 1s;
    }

    img {
      width: 100%;
      height: 465px;
      transition: all 1s;
      border-radius: inherit;
    }
  }

  .mid {
    .product-heading {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    .form {
      label {
        font-size: 18px;
        margin-bottom: 0;
      }

      .input {
        width: 40%;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        .icon {
          line-height: normal;

          &:hover {
            &~ul.price-table {
              visibility: visible;
              opacity: 1;
              transition: all 0.5s;
            }
          }
        }

        .form-control {
          padding: 10px 1pc;

          &:hover {
            &~ul.price-table {
              visibility: visible;
              opacity: 1;
              transition: all 0.5s;
            }
          }
        }
      }
    }

    ul.info {
      margin: 1pc 0;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 7px;

        span {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    ul.price-table {
      background: #cccccc24;
      position: absolute;
      width: 180px;
      bottom: 0;
      right: -190px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s;

      li {
        padding: 7px 1pc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        line-height: normal;

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }

        &:not(:first-child) {
          span {
            font-weight: 600;
            color: $brown;
          }
        }
      }

      &:hover {
        visibility: visible;
        opacity: 1;
        transition: all 0.5s;
      }
    }

    .modes {
      display: flex;
      align-items: center;
      gap: 20px;

      ul {
        display: flex;
        align-items: center;
        gap: 20px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 600;
          color: $brown2;
        }
      }
    }

    .price {
      padding: 10px 1pc;
      display: flex;
      align-items: center;
      gap: 10px;
      width: max-content;
      border: 1px solid $brown;

      b {
        text-transform: uppercase;
        font-size: 20px;
      }
    }

    .btns {
      margin: 1pc 0;
      display: flex;
      align-items: center;
      gap: 1pc;

      .btn-custom {
        --bs-btn-padding-x: 2pc;
        --bs-btn-padding-y: 13px;
      }
    }
  }
}

.product-tabs {
  .tab-description {
    ul.info {
      width: 100%;
      background: #cccccc24;

      li {
        padding: 7px 1pc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;

        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  ul.tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 2px solid $white;
    gap: 2pc;

    li {
      padding-bottom: 1pc;
      color: #000;
      font-weight: 600;
      font-size: 17px;
      cursor: pointer;

      &.active {
        color: $brown;
        border-bottom: 2px solid;
        margin-bottom: -2px;
      }
    }
  }

  .tab-inner {
    padding-top: 2pc;

    .desp {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.swiper-button {

  &-prev,
  &-next {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 20px #00000020;

    &:after {
      color: $brown;
      font-size: 25px;
      font-weight: bold;
    }
  }
}

.main-banner {
  background-size: 100%;
  background-position: center top;
  height: 85vh;
  display: flex;
  align-items: center;
  background-position: center;

  h1 {
    font-size: 55px;
  }

  .btn {
    padding: 1pc 2pc;
  }
}

.three-box .box {
  padding: 2pc;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-box {
  .image {
    position: relative;

    img {
      width: 100%;
      height: 310px;
      object-fit: cover;
    }

    ul {
      position: absolute;
      top: 1pc;
      left: 0;

      li {
        color: #fff;
        padding: 2px 1.5pc 2px 1pc;
        font-size: 14px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../images/icon/product_tag.png");
      }
    }
  }

  .content {
    padding: 1pc 1.5pc;

    p {
      margin-bottom: 0px;

      &.text-white {
        line-height: normal;

        a {
          color: inherit;
        }
      }

      &.small {
        font-weight: 500;
        margin-top: 8px;

        svg {
          font-size: 19px;
        }

        a {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}

.category-box {
  .image {
    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
  }

  .content {
    padding: 13px;
    text-align: center;

    h4 {
      margin-bottom: 0;
      font-weight: normal;
    }
  }

  &.reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.home-banner {
  // background-image: url("../images/kitchen.jpg");
  background-attachment: fixed;
  background-size: 100%;
  /* object-fit: cover; */
  background-repeat: no-repeat;
  background-position: center;

  .box {
    padding: 2pc;

    .btns {
      display: flex;
      align-items: center;
      gap: 10px;

      a {
        width: 100%;
      }
    }
  }
}

.testimonial {
  .swiper-button {

    &-prev,
    &-next {
      height: 30px !important;
      width: 30px !important;
      box-shadow: none;
      background-color: transparent !important;

      &:after {
        font-size: 16px !important;
        color: #000 !important;
      }
    }
  }

  .box {
    .desp {
      position: relative;
      padding: 1pc 0 0 2pc;
      margin: 0 auto;
      width: 80%;
      font-size: 20px;

      span:not(.icon) {
        padding: 0 10px;
      }
    }

    .designation {
      font-size: 20px;
    }
  }
}

.working-with {
  position: relative;

  h1 {
    // position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    color: #fff;
    padding: 10px 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: "IBM Plex Serif", serif;
    background-color: #d8d1c291;
    margin-top: 18px
  }

  img {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
}

.blog-box {
  text-align: center;
  // background-color: $bg_1;
  border-radius: 15px;

  .image {
    img {
      height: 65vh;
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .content {
    padding: 1pc;
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      font-weight: 600;
    }

    .desp {
      margin: 10px 0 0;
    }

    ul.tags {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      li {
        display: flex;
        align-items: flex-end;
        gap: 5px;
        color: $brown2;
        font-size: 15px;
        line-height: normal;
        font-weight: 500;
      }
    }
  }
}

.blog-detail {
  .image {
    img {
      height: 650px;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .content {
    margin-top: 1pc;

    p {
      line-height: 1.8em;
      margin-bottom: 5px;
    }

    ul.tags {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;

      li {
        display: flex;
        align-items: flex-end;
        gap: 5px;
        color: $brown2;
        font-size: 15px;
        line-height: normal;
        font-weight: 500;
      }
    }
  }
}

.contact-us {
  .bottom {
    &>h4 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 2pc;
    }

    .social-links {
      display: flex;
      align-items: center;
      gap: 15px;

      li a {
        height: 40px;
        width: 40px;
        border: 1px solid $brown;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $brown;
        border-radius: 50%;

        &:hover {
          background-color: $brown;
          color: $white;
          border-color: $brown;
        }
      }
    }

    .links {
      li {
        .inner {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 1.3pc;
        }

        .icon {
          color: $brown;
        }
      }
    }
  }
}

.faq {
  .left {
    .tabs {
      padding: 1pc;
      padding-left: 0;

      li {
        border-left: 4px solid transparent;
        transition: all 0.5s;
        padding: 10px 0 10px 1pc;
        cursor: pointer;
        font-weight: 500;
        color: gray;
        font-size: 18px;

        &.active {
          color: $black;
          border-color: $black;
          transition: all 0.5s;
        }

        // &:not(:last-child){
        //   margin-bottom: 10px;
        // }
      }
    }
  }

  .right {
    padding: 1pc;
    background-color: $bg_3;
  }
}

.accordion {
  --bs-accordion-bg: transparent;
  --bs-accordion-border-color: #c59d69;
  --bs-accordion-active-color: #9b6a42;
  --bs-accordion-btn-focus-border-color: #c59d69;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #9b6a4240;
  // --bs-accordion-btn-focus-box-shadow:none;
  --bs-accordion-active-bg: #e9e4da;
  --bs-accordion-btn-active-icon: url("../images/icon/faq_remove.png");
  --bs-accordion-btn-icon: url("../images/icon/faq_add.png");
  --bs-accordion-body-padding-y: 10px;
  --bs-accordion-btn-icon-width: 16px;

  &-header {
    font-family: $lexend;
  }

  &-body {
    font-size: 15px;
    line-height: 25px;
    padding-top: 0;
  }

  &-button {
    &:focus {
      box-shadow: none;
    }

    &:not(.collapsed) {
      border: 0;
      box-shadow: none;
    }
  }
}

.about-us {
  .left {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: center;

    img {
      width: 50%;
      height: 400px;
      object-fit: contain;

      &.small {
        height: 350px;
      }
    }
  }

  .right {
    .border-start {
      border-color: $brown2 !important;
      border-width: 4px !important;
      padding-left: 2pc;
      line-height: normal;
      margin: 1pc 0;
    }
  }
}

.about-map {
  .left {
    img {
      height: 500px;
    }
  }
}

.founder {
  .left {
    img {
      height: 400px;
      width: 80%;
    }
  }
}

.team {
  &-right {
    overflow: hidden;
  }

  &-box {
    padding: 2pc 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 300px;
      object-fit: cover;
      width: 100%;
    }

    .name {
      margin: 10px 0 0;
      // font-size: 25px;
    }
  }
}

.certificate img {
  // width: 100%;
  object-fit: cover;
  height: 150px;
}


.our-values {
  .left img {
    height: 400px;
    width: 400px;
    border-radius: 50%;
  }

  .right {
    .box {
      padding: 1pc;
      // display: flex;
      gap: 10px;
      text-align: center;

      img {
        width: 111px;
      }

      .icon {
        color: #fff;
        font-size: 22px;
      }

      .brown3 {
        color: #000 !important;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 25px;
      }

      p {
        margin-bottom: 0;
        display: none;
      }
    }
  }
}

.quote-modal {
  padding: 0;

  // border-radius: 0;
  .left {
    height: 100%;

    img {
      height: 330px;
      border-radius: 15px 0 0 15px;
    }
  }

  .right {
    padding: 2pc;

    h1.title {
      font-size: 30px;
    }

    .form {

      // [class*="col-"] {
      //   margin-bottom: 0;
      // }
      .form-control,
      .form-select {
        padding: 10px 1pc;
      }

      .checkbox-label {
        font-weight: 600;
        margin-bottom: 5px;
      }

      .inline-checks {
        display: flex;
        align-items: center;
        column-gap: 1pc;

        input[type="radio"] {
          margin: 0;
          float: none;
        }
      }

      .form-check {
        padding: 0;

        label {
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 5px;
          color: gray;
          font-weight: 400;
        }
      }
    }
  }
}

.search-form {
  position: relative;

  .icon {
    padding-left: 10px;
  }

  input {
    width: 100%;
    background-color: transparent;
    border: 0;
  }

  .form-control {
    background-color: transparent;
    border-width: 0 0 1px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.table-product {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 80px;
    width: 80px;
    border-radius: 5px;
  }

  p {
    margin: 0;
    line-height: normal;
    font-weight: 500;
  }
}

.rdt {

  &_Table,
  &_TableHeadRow,
  &_TableRow,
  &_Pagination {
    background-color: $bg_3 !important;
  }

  &_Table {
    .bzRnkJ {
      background-color: $bg_3 !important;
      font-weight: 600;
      font-size: 16px;
      color: $brown2;
    }
  }

  &_TableCell {
    padding: 1pc;
  }

  &_TableCol {
    font-weight: 600;
    font-size: 16px;
    color: $brown2;
  }
}

.table {

  tr,
  th {
    padding: 0.5rem 0.5rem;
  }

  th {
    font-weight: 600;
    font-size: 16px;
    color: $brown2;
  }

  tr {
    font-size: 13px;
    font-weight: 400;
    color: #000000de;
  }

  &-expanded {
    padding: 1pc;
  }
}

ul.action-icon {
  display: flex;
  align-items: center;
  gap: 5px;

  li {
    height: 30px;
    width: 30px;
    background: $brown;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.order-summary {
  .box {
    padding: 1pc 2pc;

    h5 {
      font-family: $lexend;
    }

    ul {
      margin: 1pc 0;

      li {
        padding: 7px 0pc;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: gray;
        }

        &:last-child {
          border-top: 1px solid #ccc;
          margin-top: 1pc;
          padding-top: 1pc;

          span {
            font-weight: 500;
            color: $black;
          }
        }
      }
    }
  }

  .box2 {
    padding: 2pc 2pc;

    ul {
      li {
        // display: flex;
        align-items: center;
        gap: 10px;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        svg {
          font-size: 13px;
        }
      }
    }
  }
}

.my-account {
  .left ul li {
    padding: 13px 1pc;
    cursor: pointer;
    font-weight: 500;
    font-size: 17px;

    &:not(:last-child) {
      border-bottom: 1px solid $bg_1;
    }

    &.active {
      color: $brown2;
      background-color: $bg_1;
    }
  }
}

.my-order {
  .title {
    width: max-content;
    position: relative;
    padding: 0;
    border-radius: 10px 10px 0 0;

    &:before {
      content: "";
      background-color: $box_bg;
      position: absolute;
      right: -28px;
      transform: skewX(38deg);
      bottom: 0;
      height: 101%;
      width: 50px;
      pointer-events: none;
      border-radius: 0 10px 0 0;
    }

    h4 {
      padding: 10px 1pc 10px 2pc;
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
      color: #fff;
      font-size: 22px;
      position: relative;
      z-index: 4;
    }
  }

  .shipping-charger {
    ul {
      max-width: 500px;
      background-color: #f5f5f5;
      padding: 10px;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 5px;
      color: #000000de;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .table-responsive+.btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    padding: 10px;

    .btn-brown {
      &-outline {
        border: 1px solid $brown;
      }

      &:hover {
        border: 1px solid $brown;
      }
    }
  }

  .table {
    --bs-table-border-color: #c0c0c0;
  }

  .rdt {

    &_Table,
    &_TableHeadRow,
    &_TableRow,
    &_Pagination {
      background-color: $bg_1 !important;
    }

    &_Table {
      .bzRnkJ {
        background-color: $bg_1 !important;
      }
    }
  }
}

.payment-page {
  ul.bank-detail {
    width: 90%;

    li {
      padding: 7px 1pc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .order-summary .box {
    ul li:last-child {
      margin: 1pc 0;
      padding: 1pc 0;
      border-bottom: 1px solid #ccc;
    }

    .get-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 1pc;

      p {
        width: 50%;
      }

      label {
        border: 1px solid $brown;
        padding: 10px 2pc;
        cursor: pointer;
        width: max-content;
        font-size: 25px;
      }
    }
  }
}

.congrats-modal {
  padding: 2pc;
}

.why-us {
  .box {
    padding: 3pc 2pc 2pc;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    min-height: 344px;
  }

  .icon {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    font-size: 40px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .outer-box {
    height: 100%;
    position: relative;
  }
}

.request-customize {
  .color {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    .color-box {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      flex: 1;

      li {
        width: 100%;
        // min-width: 150px;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      gap: 10px;

      h4 {
        margin-bottom: 0;
        background-color: $brown;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        font-weight: 600;
        font-size: 30px;
        cursor: pointer;

        span {
          line-height: 35px;
          vertical-align: top;
          color: #fff;
        }
      }
    }
  }

  img {
    height: 500px;
  }
}

.fare-style ul {
  padding-left: 30px;
  margin-bottom: 17px;

  li {
    list-style: disc;
  }
}

.marquee_p p {
  line-height: normal;
  font-size: 14px;

}

.appointment-modal {

  .form-control,
  .form-select {
    height: 46.5px;
  }
}

.list_ul {
  ul {
    display: flex;
    gap: 20px;
  }

  li {
    font-size: 23px;
    font-weight: 600;
  }
}

.middle-bar .form-control:focus {
  box-shadow: none;
}

.btn_brown_new:hover {
  background-color: transparent !important;
}

.btn_brown_new.hover_transprent:hover {
  background-color: transparent !important;
}

.btn-brown.hover_transprent:hover {
  background-color: transparent !important;
}

.profile-page {
  --bs-border-color: #c59d69;
}

.address-box-inner {
  padding: 1pc 1pc 2pc;
  position: relative;
  background-color: $bg_1;
  height: 100%;

  .links {
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    display: flex;
    align-items: center;
    gap: 15px;

    .icons {
      background: $brown;
      cursor: pointer;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff;
      transition: all 0.5s;

      &:hover {
        background-color: #000;
        transition: all 0.5s;
      }
    }
  }

  p,
  address {
    margin-bottom: 0;
  }
}

ul.chat-box {
  max-height: 45vh;
  overflow: hidden scroll;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 5px;

    &-thumb {
      background: #bebcbc;
      border-radius: 10px;
    }

    &-track {
      background: #f1f1f1;
    }
  }

  .box {
    display: flex;
    gap: 10px;
    background-color: $bg_1;
    padding: 10px;
    border-radius: 5px;
    width: 85%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    img {
      height: 60px;
      width: auto;
      object-fit: contain;
      border-radius: 50%;
      padding: 2px;
      border: 1px solid #fff;
    }

    h6 {
      margin-bottom: 0;
    }

    &.reverse {
      margin-left: auto;
    }

    p {
      line-height: normal;
    }
  }
}









.lds-grid {
  position: relative;
  width: 100vw;
  height: 80vh;
  display: grid !important;
  place-items: center !important;
}


.animation-container {
  // width: 16px;
  background-color: red !important;
  // height: 16px;
  position: relative;
}

.lds-grid .animation-container div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #C59D69;
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid .animation-container div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}

.lds-grid .animation-container div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}

.lds-grid .animation-container div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}

.lds-grid .animation-container div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}

.lds-grid .animation-container div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}

.lds-grid .animation-container div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}

.lds-grid .animation-container div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}

.lds-grid .animation-container div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}

.lds-grid .animation-container div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.working-with {
  background-image: url("../images/Home-Page_Banner3.png");
  height: 560px;
  background-position: center;
  background-size: cover;
}

.certificate {
  text-align: center;
}

.newsletterinput .form-control {
  box-shadow: none;
  border-radius: 0;
  height: 48px;
}
.imgblog {
  img{
        /* object-fit: contain; */
        // height: 738px;
        // width: 100%;
        // object-fit: cover;
        // border-radius: 15px;
        height: 645px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;

  }
}