@media only screen and (min-width: 992px) {
  .container {
    max-width: 1135px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1310px;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
  .team-box img{
    height: 250px;
  }
}

@media only screen and (min-width: 1550px) {
  .container {
    max-width: 1550px;
  }
}

@media only screen and (max-width: 992px) {
  header .topbar, header .middle-bar, header .bottom-bar {
    padding-left: 0pc;
    padding-right: 0pc;
  }

}
@media only screen and (max-width: 768px) {
  .middle-bar form.search-bar .form-control{
    min-width: auto;
  }
  .btnsmsearch{
    padding: 0px;
    font-size: 12px;
  }
  .main-banner h1 {
    font-size: 21px;
  }
  .main-banner{
    height: 50vh;
  }
  .main-logo{
    height: 54px !important;
    margin-left: 5px;
  }
  .main-banner .btn{
    padding: 8px 12px;
  }
  .mobile_scroll{
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  .mobile_scroll .col-12{
    flex: 1 1;
    min-width: 307px;
  }
  .three-box .box{
    padding: 1pc;
  }
  .title-section{
    display: unset !important;
  }
  .category-box .image img{
    height: 251px;
  }
  .title-section p{
    margin-bottom: 15px !important;
  }
  h1.title {
    font-size: 22px !important;
    font-weight: 600;
    margin:10px 0;
}
.home-banner .box .btns a {
  width: 100% !important;
}
.testimonial .box .desp {
  position: relative;
  padding: 1pc 0 0 0pc;
  margin: 0 auto;
  width: 100%;
  font-size: 15px;
}
.testimonial .swiper-button-prev, .testimonial .swiper-button-next{
  // background-color: black !important;
  display: none;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 0;
 
}
.why-us .swiper-button-prev, .swiper-rtl .swiper-button-next{
  width: 30px;
  height: 30px;
}

.why-us .swiper-button-prev:after, .swiper-button-next:after{
  font-size: 15px;
}

.why-us .swiper-button-prev, .swiper-button-next{
  width: 30px;
  height: 30px;
}
.testimonial .swiper-button-prev:after, .testimonial .swiper-button-next:after{
  color: #fff !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0px;
  left: auto;
}
.shop-page .top{
  display: unset;
}
.shop-page .top .form{
  margin: 13px 0;
}
.offcanvas-header{
  padding: 0 15px;
}
.shop-page .top h1.title{
  margin-top: 15px;
}
.w-auto {
  width: 100% !important;
}
.order-summary .box {
  padding: 0pc 1pc;
}
.product-page .left{
  display: unset;
}
.product-page ul.mini-img{
  flex-direction: row;
  gap: 5px;
  // width: 80px;
  height: 110px;
  margin-top: 15px;
}
.product-page .mid .price{
  width: auto;
  display: block;
}
.table-product{
  display: unset;
}
.title-section .btn{
  margin-bottom: 10px;
}
.list_ul ul{
  display: unset;
}
.list_ul ul li{
  margin-bottom: 10px;
}
.why-us .pb-3{
  padding-bottom: 0 !important;
}
.why-us .mb-5{
margin-bottom: 0;
}
.footer .title{
  margin-bottom: 7px;
  margin-top: 17px;
}
.bottom-bar .navbar-toggler{
  border: none;
}
.bottom-bar .navbar-toggler:focus{
  box-shadow: none;
}
.middle-bar .container-fluid{
  padding: 0;
}
.middle-bar .btn.btn-brown-outline{
  padding: 0px 15px;
  margin-left: 11px;
}
.mobile_searcbar .btn-custom.btn-brown{
  width: 140px;
  padding: 0 !important;

}
.navbar-toggler-icon{
  font-size: 15px;
}
.our-values .left img{
  width: 250px;
  height: 250px;
}
.middle-bar ul.right{
  gap: 0px;
}
.form .form-control::placeholder{
  font-size: 11px;
  margin-top: -10px;
  
}
.form .form-control, .form .form-select{
  padding: 20px 15px;
}
.form input[type=file] + .form-text{
  font-size: 12px;
  padding: 0px 7px;
}
a.brown.fw-semibold{
  display: block;
}
.offcanvas-body .btn-sm{
  padding: 10px;
}
.about-us .right .title{
  text-align: center;
}
.about-us .right .list_ul{
  text-align: center;
}
.founder .left img {
  height: 202px;
  width: 80%;
}
.about-us .left img.small{
  height: 190px;
}
.about-us .left img{
  height: 250px;
}
.title.mobile_centerbtn{
  text-align: center;
}
.about-us .left{
  margin-bottom: 10px;
}
.team-box img{
  height: 182px;
}
.order-summary .box2 ul li{
  display: unset;
}
.order-summary .box2 {
  padding: 2pc 1pc;
}
.quote-modal .right .form .inline-checks{
  display: unset;
}
.middle-bar ul.right .icon {
  font-size: 20px;
  margin-left: 7px;
}
.payment-page ul.bank-detail {
  width: 100%;
}
.certificate img{
  height: 111px;
}
.home-banner{
  background-size: cover;
}
.working-with.moblebanerhome{
  background-image: url("../images/PHON-OTTOM-BANNER.png");
  width: 100%;
  height: 447px;
  background-position: unset;  
}
.h100moile, .main-banner .container{
  height: 100%;
}
.mobilealignbeetwen{


display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
height: 100%;
padding: 1pc 2pc;
}
.main-banner h1{
  text-align: center;
}
.form input[type=date] + .form-text{
  width: 69%;
  font-size: 11px !important;
}
.form .form-control, .form .form-select, .form input[type=time] + .form-text{
  font-size: 11px !important;
}
.bank-detail.bg3 .brown2{
  text-align: right;
}

}

@media only screen and (min-width: 768px){
  .form input[type=time] + .form-text,
  .form input[type=date] + .form-text{
display: none;
  }
}

