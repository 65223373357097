@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@100;200;300;400;500;600;700&family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

$lexend: "Lexend", sans-serif;
$ibm: "IBM Plex Serif", serif;
$black: #000;
$white: #fff;
$white-50: #ffffff80;
$gray: #888888;
$bg_1: #d8d1c2;
$bg_2: #e1dacc;
$bg_3: #e9e4da;
$box_bg: #99907f;
$brown: #c59d69;
$brown2: #9b6a42;
$brown3: #7E2020;