html {
  scroll-behavior: smooth;
}

body {
  font-family: $lexend;
  color: #000000;
  line-height: 1.7em;
  font-weight: 400;
  background: $white;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ptb-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.px-4pc {
  padding-left: 4pc !important;
  padding-right: 4pc !important;
}

.btn {
  font-weight: 500;
  border-radius: 5px;
  &:focus {
    box-shadow: none;
  }
  &-custom {
    --bs-btn-padding-x: 1pc;
    --bs-btn-padding-y: 9px;
    // letter-spacing: 1px;
  }
  &-hover {
    position: relative;
    overflow: hidden;
    &:before {
      position: absolute;
      background: #ffffff80;
      height: 100%;
      width: 100%;
      top: 0;
      left: 100%;
      content: "";
      border-radius: 3px;
      transition: all 0.5s;
    }
    &:hover:before {
      transition: all 0.5s;
      left: 0;
      transform: translateX(-100%);
    }
  }

  &-black {
    background-color: #363636;
    color: $white;
    &:hover {
      background-color: #363636 !important;
      color: $white !important;
    }
  }
  &-brown {
    background-color: $brown;
    color: $white;
    &:hover {
      background-color: #e9e4da !important;
      color: $brown !important;
    }
  }
  &-brown-outline {
    background-color: transparent;
    color: $brown;
    border: 1px solid $brown;
    &:hover {
      background-color: $brown !important;
      color: $white !important;
    }
  }

  &.with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }
}

.lexend {
  font-family: $lexend !important;
}

.bg1 {
  background-color: $bg_1 !important;
}

.bg2 {
  background-color: $bg_2 !important;
}

.bg3 {
  background-color: $bg_3 !important;
}

.box-bg {
  background-color: $box_bg !important;
}

.brown {
  color: $brown !important;
}

.brown-bg {
  background-color: $brown !important;
}

.brown2 {
  color: $brown2 !important;
}

.brown2-bg {
  background-color: $brown2 !important;
}

.brown3 {
  color: $brown3 !important;
}

.brown3-bg {
  background-color: $brown3 !important;
}

.gray-bg {
  background-color: #f3f4f8 !important;
}

.fs-15 {
  font-size: 15px;
}

.ibm {
  font-family: $ibm !important;
}

.list-circle {
  li {
    list-style-type: circle;
    list-style-position: inside;
  }
}

.flex-1 {
  flex: 1;
}

h1.title {
  font-size: 36px;
}

.title-section {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .btn {
    border-radius: 3px;
  }
}

p {
  line-height: 1.8em;
  &:last-child {
    margin-bottom: 0;
  }
}

img {
  object-fit: cover;
}

.img-cover {
  object-fit: cover !important;
}

.img-contain {
  object-fit: contain !important;
}

.img-position-top {
  object-position: top !important;
}

.img-fill {
  object-fit: fill !important;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
  &:hover {
    transition: all 0.5s;
    color: $brown2;
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
  font-family: $ibm;
}

.btn-sm {
  font-size: 0.875rem;
  // padding: 7px 22px;
  border-radius: 0.2rem;
}

.main-logo {
  height: 91px;
  width: auto;
  object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;
  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: 66;
    pointer-events: none;
  }
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;
  .scroll-inner {
    height: 45px;
    width: 45px;
    background: #f16331;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #fff;
    font-size: 22px;
    cursor: pointer;
  }
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;
  &:before {
    content: "";
    position: absolute;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
    transform: translate(-2px, -2px);
    height: 15px;
    width: 15px;
    background: #128c7e;
    z-index: 0;
  }
  .call-us-inner {
    height: 55px;
    width: 55px;
    background: #128c7e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    font-size: 30px;
    transform: scaleX(-1);
    i {
      animation: phone 1.5s linear 0s infinite alternate;
    }
  }
}

.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

.line-height-normal {
  line-height: normal;
}

input:focus-visible {
  outline: 0;
}

input[type="checkbox"] {
  height: 17px;
  width: 17px;
  border: 2px solid #d0d0d0;
  border-radius: 0 !important;
  position: relative;
  background-color: #fff;
  cursor: pointer;
  appearance: none;
  &.form-check-input {
    margin: 0;
    &:focus {
      border-color: #00000040;
      box-shadow: none;
    }
    &:checked {
      background-color: $brown;
      border-color: $brown;
    }
  }
}
input[type="radio"] {
  cursor: pointer;
  &:checked {
    background-color: $brown;
    border-color: $brown;
  }
  &:focus {
    border-color: $brown;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #b0821840;
  }
}

input[type="range"] {
  &::-webkit-slider-thumb {
    background: $brown;
    &:focus {
      background: $brown !important;
    }
  }
}

input[type="color"] {
  min-height: 52px;
}

.offcanvas-header .btn-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1pc;
}

.form {
  [class*="col-"] {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  input[type="file"] + .form-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7.5pc;
    background: #f5f5f5;
    margin: 0;
    pointer-events: none;
    width: 100%;
  }
  input[type="date"] + .form-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1pc;
    background: #f5f5f5;
    margin: 0;
    pointer-events: none;
  }
  input[type="time"] + .form-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1pc;
    background: #f5f5f5;
    margin: 0;
    pointer-events: none;
  }
  .form-control,
  .form-select {
    background-color: #f5f5f5;
    border: 0;
    padding: 15px 1pc;
    font-weight: 500;
    color: $brown;
    font-size: 15px;
    &:focus {
      box-shadow: none;
      border-color: $brown;
      transition: all 0.5s;
      &::placeholder {
        opacity: 0;
        transition: all 0.5s;
      }
    }
    &::placeholder {
      color: $brown;
      opacity: 1;
      transition: all 0.5s;
      text-transform: capitalize;
    }
  }
  label {
    margin: 0;
    color: #000000;
    line-height: 20px;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
  }
}

.address-map {
  margin: 0 10px;
  padding: 8px 60px 8px 8px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top: -5px;
  left: -5px;
  text-align: left;
  z-index: 1;
  p,
  a {
    font-size: 15px;
  }
}
